import axios from "axios";
import {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import "../style/listeSalles.css";
import PhotosCarousel from "./PhotosCarousel";

export default function ListeSalles() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const espaces = axios.create({
        baseURL: `${apiUrl}`,
    });
    const [data, setData] = useState([]);
    const [types, setTypes] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Récupérer les salles
                const responseSalles = await espaces.get("/espaces");
                const sortedData = responseSalles.data.sort((a, b) => a.id - b.id);
                setData(sortedData);

                // Récupérer la table des types de salle
                const responseTypeEspaces = await espaces.get("/typesespaces");
                setTypes(responseTypeEspaces.data);
            } catch (error) {
                console.error("Une erreur s'est produite lors de la récupération des données.", error);
            }
        };

        fetchData();
    }, []);

    const getSalleType = (salle, champ) => {
        if (data.length !== 0 && types.length !== 0) {
            return Boolean(types.find((item) => item.id === salle.type_id)[champ]);
        }
        return false;
    }

    return (
        <div className="cardContainerStyle">
            {data.map((item) => (
                <div className="cardStyle" key={item.id}>
                    <div className="image-wrap">
                        <Link to={`/salle/${item.id}`}>
                            <img
                                src={item.image_principale}
                                alt={item.nom}
                                className="imageStyle"
                            />
                        </Link>
                    </div>
                    <div className="nom">
                        <Link to={`/salle/${item.id}`}>{item.nom}</Link>
                    </div>
                    <div className="description">
                        <p>{item.description}</p>
                    </div>
                    {
                        getSalleType(item, "reservable_en_ligne") ?
                            (
                                <div className="prix">
                                    {
                                        getSalleType(item, "reservable_par_heure") && <div>
                                            <p>Heure</p>
                                            <p>{item.prix_heure}€ HT</p>
                                        </div>
                                    }
                                    {
                                        getSalleType(item, "reservable_par_jour") &&
                                        <div>
                                            <p>Journée</p>
                                            <p>{item.prix_journee}€ HT</p>
                                        </div>
                                    }
                                    {
                                        getSalleType(item, "reservable_par_mois") &&
                                        <div>
                                            <p>Mois</p>
                                            <p>{item.prix_mois}€ HT</p>
                                        </div>
                                    }
                                </div>
                            ) :
                            (
                                <div className="devis">
                                    <p>Tarification</p>
                                    <p>Sur devis</p>
                                </div>
                            )
                    }

                </div>
            ))}
            
            <PhotosCarousel />
        </div>
    );
}